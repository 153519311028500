import './Home.css'
import './Navbar.css'
import { initializeApp, getApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, child, get, update, set } from 'firebase/database';
function Home(){
    setTimeout(() => {
        
    


var scrollTop = window.scrollY




const menuBtn = document.querySelector('.menu-btn');
let menuOpen = false;

menuBtn.addEventListener('click', () => {
    if(!menuOpen) {
        menuBtn.classList.add('open');
        menuOpen = true;
    } else {
        menuBtn.classList.remove('open');
        menuOpen = false;
}
});

const anchor = document.getElementById('anchor');
const menu = document.getElementById('menu');
console.log(menu.style.display == '')

anchor.addEventListener('click', () => {
    console.log('clicked')
    if (menu.style.display == 'flex') {
        menu.style.display = '';
        console.log('removed')
    }
    else if(menu.style.display == '') {
        menu.style.display = 'flex';
        console.log('displayed')
    }

})
}, 100);
const firebaseConfig = {
    apiKey: "AIzaSyBM6nBIVYFvXBWKiReJKhgVpd7Nov3d-dg",
    authDomain: "nkiformulair-889d1.firebaseapp.com",
    databaseURL: "https://nkiformulair-889d1-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "nkiformulair-889d1",
    storageBucket: "nkiformulair-889d1.appspot.com",
    messagingSenderId: "428503329011",
    appId: "1:428503329011:web:b865310fecc342ddb96f86",
    measurementId: "G-ENN9JF3WZB"
  };
  
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
    return(
        <div>
            <header>
            <nav>
            <input type="checkbox" id="check" />
            <label className="menu-btn" for="check">
                <div className="menu-btn__burger"></div>
            </label>
            <ul className="nav_links">
                <li className="li"><a className="hyper special" href="index.html"><img className="specimg" src="images/EBC_Logo.png" alt="" /></a></li>
                <li><a href="/">Accueil</a></li>
                <li className="dropli">
                    <div className="dropdown" id="anchor">
                        <a className="anchor">
                            Nos produits
                        </a>
                        <div className="menu" id="menu">
                            <div className="column">
                                <a href="verandas">Vérandas</a>
                                <a href="maconneries">Maçonneries<br></br>Gros-oeuvre</a>
                                <a href="chassis">Châssis</a>
                                <a href="volets">Volets</a>
                            </div>
                            <div className="column">
                                <a href="pergolas">Pergolas</a>
                                <a href="extensions">Extensions d'habitat</a>
                                <a href="renovations">Rénovations & entretiens</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li><a href="About">À propos</a></li>
                <li><a href="realisations">Nos réalisations</a></li>
                <li><a href="Contact">Contact</a></li>
                <li className="img"></li>
            </ul>
            <div className="img1"></div>
        </nav>
        <div className="banderole">
            <div className="jvenm">devis & conseils gratuits</div>
            <div className="jvenm2">projets personalisés</div>
        </div>
    </header>
    
    <a className='form' href='contact'></a>

    <section className="head">

        <div className="background_home">
            <img className="back-img" src="images/d.png" alt="" />
            <div className="header">
                <h1 className="nom">NKI</h1>
                <div className="separe-titlel"></div>
                <h1 className="slogan">VOTRE ESPACE EXTERIEUR SUR MESURE</h1>
            </div>
        </div>


        <img className="back-img" src="images/background." alt="" />
    </section>
    <section className="rest">

        <div className="activite" id="activite">
            <a href="verandas">
                <div className="produit p dix" id="un">
                    <div id="div-expli" className="div-expli">
                        <h3 id="h3-expli">Vérandas</h3>
                        <p id="p-expli">Trouvez plus d'informations sur nos vérandas<br />en cliquant ici</p>
                    </div>
                </div>
            </a>
            <a href="maconneries">
                <div className="produit trente" id="deux">
                    <div id="div-expli2" className="div-expli">
                        <h3 id="h3-expli" className='h3-expli_even'>Maçonneries, Gros-oeuvre
                        </h3>
                        <p id="p-expli">Trouvez plus d'informations sur nos maçonneries et gros oeuvres<br />en cliquant ici</p>
                        

                    </div>
                </div>
            </a>
            <a href="pergolas">
                <div className="produit trente" id="trois">
                    <div id="div-expli3" className="div-expli">
                        <h3 className="renova" id="h3-expli">Pergolas</h3>
                        <p id="p-expli">Trouvez plus d'informations sur nos pergolas<br />en cliquant ici</p>
                       
                    </div>
                </div>
            </a>
            <a href="extensions">
            <div className="produit dix" id="quatre">
                <div id="div-expli4" className="div-expli">
                    <h3 id="h3-expli" className='h3-expli_even'>Extensions d'habitats</h3>
                    <p id="p-expli">Trouvez plus d'informations sur nos extensions d'habitats<br />en cliquant ici</p>
                    
                </div>
            </div>
            </a>
            <a href="chassis">
            <div className="produit dix" id="cinq">
                <div id="div-expli4" className="div-expli">
                    <h3 id="h3-expli" className='h3-expli_even'>Chassis</h3>
                    <p id="p-expli">Trouvez plus d'informations sur nos chassis<br />en cliquant ici</p>
                    
                </div>
            </div>
            </a>
            <a href="renovations">
            <div className="produit dix" id="six">
                <div id="div-expli4" className="div-expli">
                    <h3 id="h3-expli" className='h3-expli_even'>Rénovations & entretiens</h3>
                    <p id="p-expli">Trouvez plus d'informations sur les rénovations et entretiens que nous effectuons en cliquant ici</p>
                    
                </div>
            </div>
            </a>
            <a href="volets">
            <div className="produit dix" id="sept">
                <div id="div-expli4" className="div-expli">
                    <h3 id="h3-expli" className='h3-expli_even'>Volets</h3>
                    <p id="p-expli">Trouvez plus d'informations sur nos volets<br />en cliquant ici</p>
                    
                </div>
            </div>
            </a>
        </div>
    </section>

    <section className="propos" id="propos">
        <div className="text-propos-home">
            <h4 className="h4-propos">NKI</h4>
            <p className="p-propos">Notre entreprise belge de construction se spécialise dans les résidences privées : villas, appartements et projets résidentiels. Dirigée par un patron expérimenté, nous offrons un service sur mesure, du gros œuvre aux finitions de qualité, et sommes experts en vérandas et pergolas. Que ce soit pour une construction ou une rénovation, nous vous accompagnons à chaque étape, en respectant les délais et les normes les plus strictes. Faites confiance à notre savoir-faire pour réaliser vos projets avec professionnalisme et précision.
            </p>

        </div>
        <div className="clients">
            <p className="client-compte" id="client-compte">+30</p>
            <div className="client-text">
                <p className="ans-p">ans</p>
                <p className="exp-p">d'experience</p>
            </div>

        </div>

        
        <img className="img-propos" src="/propos.jpg" alt="" />
    </section>
   
    <section className="information">
        <h3 className="h3-infor">Votre nouvel espace à vivre basse énergie</h3>
        <p className="p-infor">Les matériaux et nos profils menuiseries à haut rendement thermique sont de fabrication belge
            et vous permettent de profiter toute l’année de votre nouvel espace à vivre. Ils apportent une plus-value
            appréciable à votre habitat et sont en comformité avec le certificat PEB.</p>
    </section>

    <footer style={{ position:'initial'}} >
    <section className="don">
            <a className="footerimage1" href="#"><img src="/logoNKI.webp" alt=""></img></a>
            <div className="col col1">
                <h1 style={{height: '50.5px'}}>NOS PRODUITS ET ACTIVITÉS</h1>
                <div className="prod">
                    <ul className="ul1">
                        <li><a href="verandas">Vérandas</a></li>
                        <li><a href="pergolas">Pergolas</a></li>
                        <li><a href="maconneries">Maçonneries<br></br>Gros-oeuvre</a></li>
                        <li><a href="extensions">Extensions d'habitat</a></li>
                        <li><a href="chassis">Châssis</a></li>
                        <li><a href="renovations">Rénovations &<br />entretiens</a></li>
                        <li><a href="volets">Volets</a></li>
                    </ul>
                </div>
            </div>
            <div className="col col2">
                <h1 style={{height: '50.5px'}}>CONTACT</h1>
                <div className="grouping" style={{marginBottom:' 64px'}}>
                    <p style={{marginBottom: 10 + 'px'}}><b>No. de téléphone</b></p>
                    <p><u>Fixe</u>: 071/561010</p>
                    <p><u>Portable</u>: +32 498 56 01 70</p>
                    <p style={{marginBottom: 10 + 'px', marginTop: 20 + 'px'}}><b>E-mail:</b></p>
                    <p>info@n-k-i.com</p>
                </div>
            </div>
            <a className="footerimage2" href="#"><img src="/logoNKI.webp" alt=""></img></a>
        </section>
    </footer>
        </div>
    )
}
export default Home;