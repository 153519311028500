
import './Contact.css';
import './Navbar.css';
import { initializeApp, getApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase, ref, child, get, update, set } from 'firebase/database';
import firebase from 'firebase/compat/app';
import { Profiler } from 'react';
function Contact(){
    setTimeout(() => {
        const menuBtn = document.querySelector('.menu-btn');
let menuOpen = false;

menuBtn.addEventListener('click', () => {
    if(!menuOpen) {
        menuBtn.classList.add('open');
        menuOpen = true;
    } else {
        menuBtn.classList.remove('open');
        menuOpen = false;
}
});

const anchor = document.getElementById('anchor');
const menu = document.getElementById('menu');
console.log(menu.style.display == '')

anchor.addEventListener('click', () => {
    console.log('clicked')
    if (menu.style.display == 'flex') {
        menu.style.display = '';
        console.log('removed')
    }
    else if(menu.style.display == '') {
        menu.style.display = 'flex';
        console.log('displayed')
    }

})
    }, 100);
    return(
        <>
  <header>
        <nav>
            <input type="checkbox" id="check" />
            <label className="menu-btn" for="check">
                <div className="menu-btn__burger"></div>
            </label>
            <ul className="nav_links">
                <li className="li"><a className="hyper special" href="index.html"><img className="specimg" src="images/EBC_Logo.png" alt="" /></a></li>
                <li><a href="/">Accueil</a></li>
                <li className="dropli">
                    <div className="dropdown" id="anchor">
                        <a className="anchor">
                            Nos produits
                        </a>
                        <div className="menu" id="menu">
                            <div className="column">
                                <a href="verandas">Vérandas</a>
                                <a href="maconneries">Maçonneries<br></br>Gros-oeuvre</a>
                                <a href="chassis">Châssis</a>
                                <a href="volets">Volets</a>
                            </div>
                            <div className="column">
                                <a href="pergolas">Pergolas</a>
                                <a href="extensions">Extensions d'habitat</a>
                                <a href="renovations">Rénovations & entretiens</a>
                            </div>
                        </div>
                    </div>
                </li>
                <li><a href="About">À propos</a></li>
                <li><a href="realisations">Nos réalisations</a></li>
                <li><a href="Contact">Contact</a></li>
                <li className="img"></li>
            </ul>
            <div className="img1"></div>
        </nav>
        <div className="banderole">
            <div className="jvenm">devis & conseils gratuits</div>
            <div className="jvenm2">projets personalisés</div>
        </div>
    </header>

    <section className="section1"></section>
    
    <section className="section2">
        <div className="input">
            <h1>Contactez-nous pour un devis</h1>
            <input className="textContact" type="text" id='name' placeholder="Nom" />
            <input className="textContact" type="text" id='phone' placeholder="Téléphone" />
            <input className="textContact" type="text" id='mail' placeholder="E-mail" />
            <textarea name="Message" id="msg" cols="30" rows="4" placeholder="Message" ></textarea>
            <form action="#">
                <select name="produits" id="prod">
                  <option selected value='default'>Produit ou activité</option>
                  <option value="Pergola">Pergola</option>
                  <option value="Extensions d'habitats">Extensions d'habitats</option>
                  <option value="Gros oeuvre">Gros oeuvre</option>
                  <option value="Maçonnerie">Maçonnerie</option>
                  <option value="Menuiserie">Menuiserie</option>
                  <option value="Châssis">Châssis</option>
                  <option value="Volets">Volets</option>
                  <option value="Rénovation">Rénovation</option>
                  <option value="Entretiens">Entretiens</option>
                  <option value="Véranda">Véranda</option>
                </select>
          </form>
            <label className="checkbox">
                <input id='checkbox_données' className="checkbox__input" type="checkbox" />
                <div className="checkbox__box" id='checkbox_données_div'></div>
                <h2>J’accepte que mes données envoyées dans ce formulaire soit utilisées</h2>
            </label>
            <label className="checkbox">
                <input  id='checkbox_renseignement'  className="checkbox__input" type="checkbox" />
                <div className="checkbox__box" id='checkbox_renseignement_div'></div>
                <h2>Je désire un renseignement</h2>
            </label>
            <label className="checkbox">
                <input id='checkbox_devis' className="checkbox__input" type="checkbox" />
                <div className="checkbox__box" id='checkbox_devis_div'></div>
                <h2>Je désire un devis personnalisé et le passage du conseiller technique</h2>
            </label>
            <div id="send" className="send" onClick={()=>{
                document.getElementById('send').style.border = '3px solid #fff'
                console.log('helllo');
                     
                const firebaseConfig = {
                    databaseURL:'https://nkiformulair-889d1-default-rtdb.europe-west1.firebasedatabase.app',
                    apiKey: "AIzaSyBM6nBIVYFvXBWKiReJKhgVpd7Nov3d-dg",
                    authDomain: "nkiformulair-889d1.firebaseapp.com",
                    projectId: "nkiformulair-889d1",
                    storageBucket: "nkiformulair-889d1.appspot.com",
                    messagingSenderId: "428503329011",
                    appId: "1:428503329011:web:b865310fecc342ddb96f86",
                    measurementId: "G-ENN9JF3WZB",
                    
                
                  };
                  
              
                  let app;
                if (!getApps().length) {
                    app = initializeApp(firebaseConfig); // Initialize Firebase if no app exists
                } else {
                    app = getApp(); // Use existing app instance if already initialized
                }
       
                  var name = document.getElementById('name').value
                  var phone = document.getElementById('phone').value
                  var mail = document.getElementById('mail').value
                  var message = document.getElementById('msg').value
                  var produit = document.getElementById('prod').value
                  var données = document.getElementById('checkbox_données')
                  var renseignement = document.getElementById('checkbox_renseignement')
                  var devis = document.getElementById('checkbox_devis')
                  const database = getDatabase()
                  
              
                  if (données.checked == true &&  mail != '' && mail.includes('@') && name != ''&& phone !='') {
                    console.log(données.checked)
                    
                      update(ref(database, 'Utilisateur/'+mail.replace(/[^\w\s]/gi, '')),{
                      Name: name,
                      Phone: phone,
                      Mail:mail,
                      Message: message,
                      Produit: produit,
                      Renseignement: renseignement.checked,
                      Devis: devis.checked
                     
                     
                      
                    }
                
              
                ).then(()=>{

                    document.getElementById('name').value = ''
                    document.getElementById('phone').value =''
                    document.getElementById('mail').value = ''
                    document.getElementById('msg').value = ''
                    document.getElementById('prod').value = 'default'
                    données.checked = false
                    renseignement.checked = false
                    devis.checked = false
                     document.getElementById('checkbox_données_div').style.borderColor = 'white'
                      document.getElementById('mail').style.border = 'none'
                      document.getElementById('mail').style.borderBottom = '3px solid #fff'
                      document.getElementById('name').style.border = 'none'
                      document.getElementById('name').style.borderBottom = '3px solid #fff'
                      document.getElementById('phone').style.border = 'none'
                      document.getElementById('phone').style.borderBottom = '3px solid #fff'
                      document.getElementById('send').style.border = '3px solid green'
                      

                }
                )
                  }
                  else{
                    if(données.checked != true){
                        document.getElementById('checkbox_données_div').style.borderColor = 'red'
                    }
                   if(mail == ''){
                    document.getElementById('mail').style.border = '2px solid red'
                   }
                   if(mail.includes('@') == false){
                    document.getElementById('mail').style.border = '2px solid red'
                   }
                   if(name == ''){
                    document.getElementById('name').style.border = '2px solid red'
                   }
                   if(phone == ''){
                    document.getElementById('phone').style.border = '2px solid red'
                   }
                  }
               
                 
            }} >Envoyer</div>
        </div>
        <div className="coord">
            <div className="nc">
                <h1>Nos coordonnées</h1>
                <p className="p1">Téléphone:</p>
                <div className='telephone'>
                    <div className='fixe'>
                        <p className="p2"><u>Fixe</u>:</p>
                        <p className="p3">071/561010</p>
                    </div>
                    <div className='fixe'>
                        <p className="p2"><u>Portable</u>:</p>
                        <p className="p3">+32 498 56 01 70</p>
                    </div>
                </div>
                <p className="p1">E-mail:</p>
                <p className="p3">info@n-k-i.com</p>
            </div>
            <div className="nss">
                <h1>Nos partenaires</h1>
                <div className='partenaires'>
                    <div className='partenaire partenaire1'>
                        <a href='https://www.reynaers.be/fr' target="_blank" rel="noopener noreferrer"><img src='/logo-reynaers.webp' alt="" /></a>
                    </div>
                    <div className='partenaire partenaire2'>
                        <a href='https://www.storyapi.com/pallecio-pal7100-sliding-system/' target="_blank" rel="noopener noreferrer"><img src='/Screenshot 2024-10-11 200044.png' alt="" /></a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <footer style={{ position:'initial'}} >
        <section className="don">
            <a className="footerimage1" href="#"><img src="/logoNKI.webp" alt=""></img></a>
            <div className="col col1">
                <h1 style={{height: '50.5px'}}>NOS PRODUITS ET ACTIVITÉS</h1>
                <div className="prod">
                    <ul className="ul1">
                        <li><a href="verandas">Vérandas</a></li>
                        <li><a href="pergolas">Pergolas</a></li>
                        <li><a href="maconneries">Maçonneries<br></br>Gros-oeuvre</a></li>
                        <li><a href="extensions">Extensions d'habitat</a></li>
                        <li><a href="chassis">Châssis</a></li>
                        <li><a href="renovations">Rénovations &<br />entretiens</a></li>
                        <li><a href="volets">Volets</a></li>
                    </ul>
                </div>
            </div>
            <div className="col col2">
                <h1 style={{height: '50.5px'}}>CONTACT</h1>
                <div className="grouping" style={{marginBottom:' 64px'}}>
                    <p style={{marginBottom: 10 + 'px'}}><b>No. de téléphone</b></p>
                    <p><u>Fixe</u>: 071/561010</p>
                    <p><u>Portable</u>: +32 498 56 01 70</p>
                    <p style={{marginBottom: 10 + 'px', marginTop: 20 + 'px'}}><b>E-mail:</b></p>
                    <p>info@n-k-i.com</p>
                </div>
            </div>
            <a className="footerimage2" href="#"><img src="/logoNKI.webp" alt=""></img></a>
        </section>
    </footer>

    </>
    )
}

export default Contact;